






























import Vue from "vue";
import { VerifyEmail } from "@/interfaces/auth/verify_email";
import SubmitButton from "@/components/auth/SubmitButton.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_USER_DETAILS,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD_TOKEN
} from "@/store/modules/auth/constants";
import router from "@/router";
export default Vue.extend({
  name: "TokenVerification",
  components: { SubmitButton },
  async created() {
    const code = this.$route.params.code;
    if (code) {
      this.verification_code = code;
      await this.submit_form();
    }
  },
  data(): VerifyEmail {
    return {
      decoration_img_src: require("@/assets/illustrations/email.svg"),
      verification_code: "",
      is_valid: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS,
      get_reset_pass_email: RESET_PASS_USER_EMAIL
    })
  },
  watch: {
    verification_code(new_value: string) {
      this.is_valid = /^[0-9]{4}$/.test(new_value);
    }
  },
  methods: {
    ...mapMutations("auth", {
      set_reset_pass_token: RESET_PASSWORD_TOKEN
    }),
    async submit_form() {
      if (this.verification_code && this.verification_code.length === 4) {
        this.set_reset_pass_token(this.verification_code);
        await router.push("/auth/reset-password/create-new-password");
      }
    }
  }
});
